
  .modal_t {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(77, 77, 77, 0.7);
    transition: all 0.4s;
    height: auto;
    z-index: 1;
  }
  
  .modal_t:target {
    visibility: visible;
    opacity: 1;
  }
  
  .modal__content_t {
    border-radius: 4px;
    position: relative;
    width: 500px;
    max-width: 90%;
    background: #fff;
    padding: 1em 2em;
    width: 50%;
  }

  @media only screen and (max-width: 580px) {
    .modal__content_t {
        border-radius: 4px;
        position: relative;
        width: 500px;
        max-width: 90%;
        background: #fff;
        padding: 1em 2em;
        width: 100%;
      }
  }
  
  
  .modal__close_t {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #585858;
    text-decoration: none;
    font-size: 20px;
    font-weight: bolder;
  }
  